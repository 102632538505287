<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import CommonApi from "@/api/common"
export default {
  name: 'app',
  provide() { //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  created(){
    this.getTicket()
    this.getNotice()
  },
  data() {
    return {
      isRouterAlive: true //控制视图是否显示的变量
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true; //再打开
      })
    },
    // 获取Ticket
    getTicket(){
     CommonApi.getTicket().then(res => {
        // console.log(res.data)
        localStorage.setItem('key',res.data.key)
        localStorage.setItem('ticket',res.data.ticket)
        // console.log('获取 投放信息')
        setTimeout(()=>{
          this.getRouteQuery()
        },1000)
      }).catch(() => {
      })
    },
    // 获取 投放信息
    getRouteQuery(){
      let data = {
        tag: '',
        type: ''
      }
      // type= 360 & qhclickid= xxx
      let tag
      let type = this.$route.query.type
      let qhclickid = this.$route.query.qhclickid
      if (type){
        tag = qhclickid || (type.includes("baidu") ? window.location.href : "")
        // 获取媒体标识
        if(type.includes("baidu")){
          this.getLaunchTag(type)
        }
      }
      // console.log('tag=',tag,'type=', type)
      if(tag && type){
        data.tag = tag
        data.type = type
        // console.log('设置 投放信息')
        this.setLaunchInfo(data)
      }
    },
    // 设置投放信息
    setLaunchInfo(dto){
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      CommonApi.setLaunchInfo(dto,key,time,infoMd5).then(res => {
        // console.log(res)
      }).catch(() => {
      })
    },
    // 获取公告
    getNotice() {
      CommonApi.getNotices().then((res) => {
        let notices = JSON.stringify(res.data)
        localStorage.setItem('notices',notices)
      })
    },
    // 获取媒体标识
    async getLaunchTag(type){
     await CommonApi.postGetLaunchTag(type).then((res) => {
        sessionStorage.setItem('LaunchTag',res.data)
        this.doBrowseFirstInReport(res.data)
      })
    },
    //第一次进来直接上报访问路径  后面的路由切换再上报滞留地址  在main.js里面
    doBrowseFirstInReport(tag){
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?" + tag;
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    }
  }
}
</script>
