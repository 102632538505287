import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import InfiniteScroll from 'vue-infinite-scroll'
import md5 from 'js-md5'

Vue.prototype.$md5 = md5
Vue.use(InfiniteScroll)
/* 全局样式 */
import './assets/css/global.css'
Vue.config.productionTip = false

Vue.prototype.payOrderUrl = '/index/IntelligentPay' //支付订单url
Vue.prototype.paySuccessUrl = '/index/paySuccess' //支付完成后跳转url
Vue.prototype.orderQueryUrl = '/index/order' //支付完成后订单查询跳转url

//过滤器--转换时间格式为年月日时分秒
Vue.filter('dateFormat', function(originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

//禁用复制 指令
Vue.directive('disable-select', {
  inserted: function (el) {
    el.style.userSelect = 'none'
    el.onmousedown = function (e) { e.preventDefault(); return false }
  }
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// 加入百度统计
router.beforeEach((to, from, next) => {
  let launchTag = sessionStorage.getItem('LaunchTag') || false
  if( launchTag ){
    if (to.path) {
      if (window._hmt) {
        console.log("上报媒体平台:"+launchTag)
        window._hmt.push(['_trackPageview', to.fullPath])
      }
    }
  }
  next()
})
